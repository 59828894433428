import {Customer} from '@hconnect/apiclient'
import {ErrorOutline, DeleteOutline, Search} from '@mui/icons-material'
import {CircularProgress, IconButton, TextField, TextFieldProps} from '@mui/material'
import clsx from 'clsx'
import React from 'react'

import {useStyles} from './WidgetCustomerSearchInput.styles'

type WidgetCustomerSearchInputProps = TextFieldProps & {
  handleSubmit: () => void
  label: string
  setSelectedCustomerId: (customerId?: string) => void
  customerResult?: Customer[]
  disabled?: boolean
  error?: boolean
  loading?: boolean
}
export const WidgetCustomerSearchInput = ({
  handleSubmit,
  label,
  disabled,
  error,
  loading,
  customerResult,
  setSelectedCustomerId,
  ...props
}: WidgetCustomerSearchInputProps) => {
  const {classes} = useStyles()

  const handleAdornments = () => {
    if (loading) {
      return <CircularProgress size={16} />
    }

    if (error) {
      return <ErrorOutline style={{color: 'rgba(218, 9, 1, 1)', fontSize: '24px'}} />
    }

    if (customerResult && customerResult.length > 0) {
      return (
        <IconButton onClick={() => setSelectedCustomerId(undefined)}>
          <DeleteOutline style={{fontSize: '24px'}} />
        </IconButton>
      )
    }

    return (
      <IconButton onClick={() => handleSubmit()}>
        <Search style={{color: '#00374d'}} />
      </IconButton>
    )
  }

  return (
    <TextField
      {...props}
      disabled={disabled}
      label={label}
      value={
        customerResult && customerResult?.length > 0
          ? props.value + ` (${customerResult?.[0].customerName})`
          : props.value
      }
      variant="filled"
      className={clsx(classes.searchInput, {
        [classes.searchInputError]: error
      })}
      InputLabelProps={{shrink: true, className: classes.searchInputLabel}}
      InputProps={{
        readOnly: customerResult && customerResult?.length > 0,
        endAdornment: handleAdornments()
      }}
    />
  )
}
